import React from "react";
import Header from "../components/Header/Header";
import "./About.css";
import WeAre from "../components/About/WeAre";
import Text from "../components/About/Text";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import HowItWorks from "../components/Home/HowItWorks";

const About = () => {
  return (
    <Layout>
      <div className="container about-container"></div>
    </Layout>
  );
};

export default About;
